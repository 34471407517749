import { Injectable, inject } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import {
  FlashyPlan,
  IProductSubscription,
  TFlasyCancelPlan,
} from '@flashy/store/plan';
import { FlashyTokenService, UserStore } from '@flashy/users/data-access';
import { getDateDifference } from '@flashy/utils';

import { AccountStore } from 'account/data-access';

@Injectable({
  providedIn: 'root',
})
export class TagManagerService {
  accountStore = inject(AccountStore);
  userStore = inject(UserStore);

  constructor(
    private gtmService: GoogleTagManagerService,
    private tokenService: FlashyTokenService
  ) {}

  sendPageEvent(): void {
    this.gtmService.pushTag({ event: 'pageView' });
  }

  sendUserData(): void {
    this.gtmService.pushTag({
      event: 'userData',
      account: this.accountStore.selectedAccount(),
      user: this.userStore.user(),
      tokens: this.tokenService.tokens,
      newAccount:
        getDateDifference(
          this.accountStore.selectedAccount()!.created_at,
          'd'
        ) <= 14, // Is old than 2 weeks - false
    });
  }

  upgradeEvent(event: string, newPlan: FlashyPlan, source: string): void {
    this.gtmService.pushTag({
      event,
      source,
      plan: {
        value: newPlan.quantity,
        name: newPlan.name,
      },
    });
  }

  planCanceledEvent(
    plan: FlashyPlan | IProductSubscription,
    reason: TFlasyCancelPlan
  ): void {
    this.gtmService.pushTag({
      event: 'cancelledPlan',
      reason,
      plan: {
        value: plan.quantity,
        name: plan.name,
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pushTag(event: string, params?: Record<string, any>): void {
    this.gtmService.pushTag({ event, ...params });
  }

  newUserEvent(): void {
    this.gtmService.pushTag({
      event: 'newUser',
      user: this.userStore.user(),
    });
  }
}
